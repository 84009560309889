<template>
<Intersect @enter.once="animation1=true" @leave.once="animation1=false" :threshold="[1.0]">
    <div class="height-div">
        <transition name="slide-fade">
            <div id="entregas" class="flex-item-row offset" v-if="animation1"> 
                <p class="title-2"> Yipi te lo lleva</p>
        
                <p class="subtitle-6 width-parragraph" style="text-align:center;line-height:1.3"><span class="green-weight">Yipi</span> fomenta el <span class="green-weight">consumo local</span>  
                    a través de nuestros<span class="green-weight"> repartos de última milla</span> en polígonos definidos </p>
                            <DialogFormEntrega :dialog="dialog" :setDialog="setDialog"/>
                    <img @click="setDialog" class="mt-10 btn" src="../assets/320x480/Boton_Formulario.png"/>
            
            
            <picture >
                   <source media="(max-width: 400px)" srcset="../assets/animacion/08/Yipi_AnimacionWeb_08_Celular.gif">
                    <source media="(max-width: 700px)" srcset="../assets/animacion/08/Yipi_AnimacionWeb_08_Ipad.gif">
                    <img class="video-animation" src="../assets/animacion/08/Yipi_AnimacionWeb_08.gif" alt="animation yipi">
            </picture>
            </div>
        </transition>
    </div> 
</Intersect>   
</template>
<script>
  import Intersect from 'vue-intersect'
import DialogFormEntrega from "../components/DialogFormEntrega.vue";
export default {
    
    data: ()=>({
        dialog:false,
        animation1:false
    }),
    methods:{
        setDialog(){
       this.dialog = !this.dialog;
        }
    },
    components:{
        DialogFormEntrega,
        Intersect
    }
}
</script>
<style >
  @import '../styles/entrega.css';
</style>

