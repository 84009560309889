
<template>
  <v-row justify="center">

    <DialogInfo :dialog="dialogInfo" :title="dialogTitle" :mensaje="dialogMensaje" :setDialog="setDialogInfo"/>
    <v-dialog :value="dialog" @input="setDialog" max-width="750px">
      <v-card rounded="xl">
        <v-container>        
            <FormEntrega  :fields="fields"  @setData="registrarConductor()"  subtitle="Déjanos tus datos y nos pondremos en contacto contigo." 
              titleClass="title-form"
              title=""
           spanTitle="YIPI" titleEnd=" te lo lleva" sizeClass="padding-form" />
      </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import FormEntrega from "./commons/Form.vue";
import DialogInfo from "./commons/DialogInfo.vue";
import {emailValidation,nombreValidation,telefonoValidation,ubicacionValidation} from"@/utils/validation";
import {postApiResource} from '../api/requestApi';
export default {
 data: () => ({
     dialogInfo:false,
     dialogMensaje:'',
     dialogTitle:'',
     fields: [
      {
        cols: "5",
        label: "Nombre",
        rules: nombreValidation,
        vmodel: "",
        index: 1,
      },
      {
        cols: "7",
        label: "Telefono",
        rules: telefonoValidation,
        icon: "$telefono",
        vmodel: "",
        index: 2,
      },
      {
        cols: "12",
        label: "Email",
         rules: emailValidation,
        icon: "$email",
        vmodel: "",
        index: 3,
      }, 
      {
        cols: "12",
        label: "Origen",
        rules: ubicacionValidation,
        icon: "$ubicacion2",
        vmodel: "",
        index: 4,
      },
           {
        cols: "12",
        label: "Destino",
        rules: ubicacionValidation,
        icon: "$ubicacion2",
        vmodel: "",
        index: 5,
      },
    ],
  }),
  methods: {
      async registrarConductor() {
        const conductorData= {
       nombre: this.fields[0].vmodel,
        telefono: this.fields[1].vmodel,
        correo: this.fields[2].vmodel,
        origen: this.fields[3].vmodel,
        destino: this.fields[4].vmodel,
        };
      
        const {data} = await postApiResource("/request-page/entrega",conductorData)
        this.dialogInfo=true;
        if (data.statusCode == 200){
            this.dialogTitle="Solicitud enviada";
             this.dialogMensaje="Hemos enviado su solicitud correctamente, pronto nos pondremos en contacto";
        } else{
               this.dialogTitle="Error";
             this.dialogMensaje="Lo sentimos, ha ocurrido un error, porfavor intentelo mas tarde";
        }
 
            
      
    },
    setDialogInfo(){
      this.dialogInfo=!this.dialogInfo;
    },
  },
  components: {
    DialogInfo,
    FormEntrega
  },
  props: ["dialog", "setDialog"],
}
</script>

<style scoped>

.v-application p{
  margin-bottom: 0px !important;
}
.padding-form{

padding: 15px;
}
.m-0{
  margin: 0;
  padding:0;
}
</style>